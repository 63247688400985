import React, { useState, useEffect } from "react"
import Particles from "react-particles-js"
import Button from "@material-ui/core/Button"

import Particle1 from "../../../static/particles/particle-1.png"
import Particle2 from "../../../static/particles/particle-2.png"
import Particle3 from "../../../static/particles/particle-3.png"
import Particle4 from "../../../static/particles/particle-4.png"
import Particle5 from "../../../static/particles/particle-5.png"
import Particle6 from "../../../static/particles/particle-2.png"
import Particle7 from "../../../static/particles/particle-4.png"

const ParticlesComponent = () => {
  const [animationState, setAnimationState] = useState(true)

  useEffect(() => {
    setAnimationState(
      localStorage.getItem("animationState") == "false" ? false : true
    )
  }, [])

  const changeAnimationState = () => {
    setAnimationState(!animationState)
    localStorage.setItem("animationState", !animationState)
  }

  return (
    <>
      {animationState == true && (
        <Particles
          className="particle"
          params={{
            particles: {
              number: {
                value: 4,
                density: { enable: true, value_area: 800 },
              },
              shape: {
                type: ["images"],
                images: [
                  {
                    src: `${Particle1}`,
                    width: 25,
                    height: 25,
                  },
                  {
                    src: `${Particle2}`,
                    width: 18,
                    height: 18,
                  },
                  {
                    src: `${Particle3}`,
                    width: 32,
                    height: 32,
                  },
                  {
                    src: `${Particle4}`,
                    width: 41,
                    height: 41,
                  },
                  {
                    src: `${Particle5}`,
                    width: 22,
                    height: 22,
                  },
                  {
                    src: `${Particle6}`,
                    width: 22,
                    height: 22,
                  },
                  {
                    src: `${Particle7}`,
                    width: 22,
                    height: 22,
                  },
                ],
              },
              opacity: {
                value: 0.17626369048095938,
                random: true,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 10,
                random: false,
              },
              line_linked: {
                enable: false,
              },
              move: {
                enable: true,
                speed: 1.5,
                direction: "none",
                random: false,
                straight: false,
                bounce: true,
                attract: { enable: true, rotateX: 100, rotateY: 400 },
              },
            },
            retina_detect: true,
          }}
        />
      )}
      <Button
        onClick={changeAnimationState}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          fontSize: 10,
          opacity: 0.5,
        }}
      >
        {animationState == true ? "Turn Off animation" : "Turn On animation"}
      </Button>
      <style>
        {`
          .particle {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            @media (max-width: 990px) {
              display: none;
            }
          }
        `}
      </style>
    </>
  )
}
export default ParticlesComponent
